import "./App.css";
import { useEffect, useState } from "react";
// import logo from "multi-logos.svg";

function App() {
  const [isVideo, setIsVideo] = useState(false);

  useEffect(() => {
    const closeVideo = () => {
      if (isVideo) {
        setIsVideo(false);
      }
    };

    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        closeVideo();
      }
    });

    return document.removeEventListener("keydown", closeVideo);
  });

  return (
    <div className="App">
      <div className="top">
        <h1 className="heading">Decentralized Knowledge.</h1>
        <h2 className="subheading">Open Academy</h2>
      </div>

      <div className={`video-wrap ${isVideo ? "active" : ""}`}>
        <div className={`center-container`}>
          <video className="video" controls>
            <source src="/press.mp4" type="video/mp4" />
          </video>
          <img
            className="close-button"
            src="/close.svg"
            alt="close"
            onClick={(_) => setIsVideo(false)}
          />
        </div>
      </div>

      <footer>
        <h3 onClick={(_) => setIsVideo(true)} className="video-link">
          Watch Video
        </h3>
        <img className="logo" alt="multiversety logo" src="/multi-logos.svg" />
      </footer>
    </div>
  );
}

export default App;
